import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Row,Button,Container,Form, Col } from "react-bootstrap"
import BanneronePage from "../components/banneronePage"
import CardPagina from "../components/cardPagina"
import Sezione from "../components/sezione"

const Contatti = () => (
  <Layout isPagina={true}>
    <SEO
      title="Contatta NonsoloEnergia SRL per avere informazioni e preventivi su impianti fotovoltaici nel Lazio"
      description="Richiedi informazioni per la realizzazione di impianti fotovoltaici o di servizi di ingegneria energetica in tutto il Lazio"
    />
    <BanneronePage title="Contatti" />
    <Container className="mt-4 mb-4">
      <Row>
        <Col sm={4} className="text-center justify-content-center">
          {" "}
          <img
            className="rounded mt-1 mx-auto img-fluid "
            src="../nonsoloenergiatitle.png"
            alt="Produzione acqua calda industriale ed energia elettrica"
          />
        </Col>
        <Col sm={8}>
          {" "}
          <CardPagina variante="bordoLeft">
            <h3>NonsoloEnergia srl</h3>
            <h4>Soluzioni Integrate di Ingegneria Energetica</h4>
            <p>
              <b>Sede legale e uffici: via Rea Silvia, ANZIO (RM)</b>
            </p>
            <p>
              Sedi operative: via Palmarola APRILIA (LT), via Paiella, ANZIO (RM)
              <br />
              Tel. 06 9865300 (
              <a href="https://api.whatsapp.com/send?phone=+39 069865300">
                <img
                  className="mx-auto"
                  src="../nonsoloenergia-whatsapp.png"
                  alt="Whatsapp NonsoloEnergia SRL"
                />
                )
              </a>
              <br />
              Fax 06 874598871
              <br />
              <a
                className="testo-verde"
                href="mailto:info@nonsoloenergia.com"
                rel="nofollow"
              >
                info@nonsoloenergia.com
              </a>
              <br />
              <a
                className="testo-verde"
                href="mailto:nonsoloenergia@pec.it"
                rel="nofollow"
              >
                nonsoloenergia@pec.it
              </a>
            </p>
            <h5>Resp. Settore Amm.vo - Comm.le</h5>
            <p>
              <b>Rosanna GIORDANO</b>
              <br />
              <a
                className="testo-verde"
                href="mailto:marketing@nonsoloenergia.com"
                rel="nofollow"
              >
                marketing@nonsoloenergia.com
              </a>
            </p>
            <p>
              <h5>Ingegneria Energetica e Servizi di Ingegneria</h5>
              <b>Ingg. DI MEZZA, FABBRI, LEONE</b> <br />
              <a
                className="testo-verde"
                href="mailto:engineering@nonsoloenergia.com"
                rel="nofollow"
              >
                engineering@nonsoloenergia.com
              </a>
            </p>
          </CardPagina>
        </Col>
      </Row>

      <Sezione title="Richiesta Informazioni" />
      <CardPagina>
        <Form
          action="https://f5xplobb67.execute-api.eu-west-1.amazonaws.com/dev/"
          method="POST"
        >
        
          <input
            type="hidden"
            name="_to"
            value="dc9dbf3822fb3b3d98541d2f847c47d3053cc0bebb5eeb"
          />
          <input
            type="hidden"
            name="_redirect"
            value="https://nonsoloenergia.com/form-ok"
          />{" "}
          <Form.Row>
            {" "}
            <Form.Group as={Col} sm="5" controlId="nome">
              <Form.Label>Nome e Cognome*</Form.Label>
              <Form.Control
                required
                type="text"
                name="nome e cognome"
                placeholder="Inserisci Nome e Cognome"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="5" controlId="luogo">
              <Form.Label>Località Intervento*</Form.Label>
              <Form.Control
                required
                type="text"
                name="località intervento"
                placeholder="Indicaci la città dell'intervento"
              />
            </Form.Group>
            <Form.Group as={Col} md="7" controlId="tipologiaIntervento">
              <Form.Label>Tipologia Intervento</Form.Label>
              <Form.Control required as="select" name="Tipologia Intervento">
                <option>Fotovoltaico</option>
                <option>Efficientamento Energetico</option>
                <option>Solare Termico / Termodinamico</option>
                <option>Termografia a Infrarossi</option>
                <option>Consulenza Energy Manager</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>Indirizzo Email*</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Inserisci la tua Email"
              />
              <Form.Text className="text-muted">
                Digita un indirizzo email corretto per consentirci di
                contattarti
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>Telefono*</Form.Label>
              <Form.Control
                required
                type="number"
                name="telefono"
                placeholder="Inserisci il tuo numero di Telefono"
              />
              <Form.Text className="text-muted">
                Ti contatteremo noi. Assicurati che sia corretto.
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="richiesta">
            <Form.Label>Richiesta</Form.Label>
            <Form.Control
              required
              as="textarea"
              name="altre informazioni"
              rows="3"
              placeholder="Descrivici il problema"
            />
          </Form.Group>
          <Form.Group id="tipo di offerta">
            <Form.Label>
              <b>
                I dati che Vi riguardano saranno archiviati e trattati nel
                rispetto di quanto stabilito in merito al trattamento dei dati
                degli utenti ai fini dell’art. 13 del D.lgs. n. 196/2003, Codice
                in materia di protezione dei dati personali, e ai fini dell’art.
                13 del Regolamento UE n. 2016/679, ed usati esclusivamente per
                la contattarvi.
              </b>
            </Form.Label>
            <Form.Check
              required
              type="checkbox"
              name="Privacy Accettata"
              label="Accetto"
            />
          </Form.Group>
          <div className="text-center">
            <Button className="btnVerde" type="submit">
              Invia la tua richiesta
            </Button>
          </div>
        </Form>
      </CardPagina>
    </Container>
  </Layout>
)

export default Contatti
