import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Button, Container, Form, Col } from "react-bootstrap"
import BanneronePage from "../components/banneronePage"
import CardPagina from "../components/cardPagina"
import Sezione from "../components/sezione"
import TitoloPagina from "../components/titoloPagina"

const RichiestaInformazioni = () => (
  <Layout isPagina={true}>
    <SEO
      title="Richiesta Informazioni per impianti fotovoltaici, servizi di termografia Lazio"
      description="Contattaci per informazioni inerente i nostri servizi. Impianti fotovoltaici, servizi di termografia e altro a Roma Anzio Nettuno Latina Pomezia"
    />
    <BanneronePage title="Richiesta Informazioni" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Informazioni per i servizi di ingegneria energetica Lazio, Roma, Latina, Aprilia, Anzio, Nettuno, Ardea" />

      <Sezione title="Richiesta Informazioni" />
      <CardPagina>
        <Form
          action="https://f5xplobb67.execute-api.eu-west-1.amazonaws.com/dev/"
          method="POST"
        >
          <input
            type="hidden"
            name="_to"
            value="dc9dbf3822fb3b3d98541d2f847c47d3053cc0bebb5eeb"
          />
          <input
            type="hidden"
            name="_redirect"
            value="https://nonsoloenergia.com/form-ok"
          />
          <Form.Row>
            <Form.Group as={Col} sm="5" controlId="nome">
              <Form.Label>Nome e Cognome*</Form.Label>
              <Form.Control
                required
                type="text"
                name="nome e cognome"
                placeholder="Inserisci Nome e Cognome"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="5" controlId="azienda">
              <Form.Label>Denominazione Azienda/Ente Pubblico</Form.Label>
              <Form.Control
                type="text"
                name="Denominazione Azienda/Ente Pubblico"
                placeholder="Campo riservato alle aziende"
              />
            </Form.Group>
            <Form.Group as={Col} sm="5" controlId="regione sociale">
              <Form.Label>Ragione Sociale</Form.Label>
              <Form.Control
                type="text"
                name="Ragione sociale"
                placeholder="Campo riservato alle aziende"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="5" controlId="indirizzo">
              <Form.Label>Comune*</Form.Label>
              <Form.Control
                required
                type="text"
                name="Comune"
                placeholder="Comune"
              />
            </Form.Group>
            <Form.Group as={Col} sm="4" controlId="Provincia">
              <Form.Label>Provincia*</Form.Label>
              <Form.Control
                required
                type="text"
                name="Provincia"
                placeholder="Provincia"
              />
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="Indirizzo">
            <Form.Label>Indirizzo</Form.Label>
            <Form.Control
              type="text"
              name="Indirizzo"
              placeholder="Indirizzo"
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>Indirizzo Email*</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Inserisci la tua Email"
              />
              <Form.Text className="text-muted">
                Digita un indirizzo email corretto per consentirci di
                contattarti
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>Telefono*</Form.Label>
              <Form.Control
                required
                type="number"
                name="telefono"
                placeholder="Inserisci il tuo numero di Telefono"
              />
              <Form.Text className="text-muted">
                Ti contatteremo noi. Assicurati che sia corretto.
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="7" controlId="superficieTotale">
              <Form.Label>Tipologia richiesta</Form.Label>
              <Form.Control as="select" name="Tipologia Richiesta">
                <option>Consulenza energetica edificio</option>
                <option>Impianti fotovoltaici</option>
                <option>Impianti termodinamici per ACS</option>
                <option>Riqualificazione energetica edifici</option>
                <option>Impianti di climatizzazione</option>
                <option>Mobilità sostenibile</option>
                <option>Check-up con termografia a infrarossi</option>
                <option>Servizi di Ingegneria</option>
                <option>Attestato di Prestazione Energetica</option>

                <option> Rimozione Eternit - Amianto</option>

                <option>Collaborazioni</option>
                <option>Altro</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="richiesta">
            <Form.Label>Messaggio</Form.Label>
            <Form.Control
              as="textarea"
              name="Messaggio"
              rows="5"
              placeholder="Scrivi il tuo messaggio. Lo analizzeremo appena possibile!"
            />
          </Form.Group>
          <Form.Group id="tipo di offerta">
            <Form.Label>
              <b>
                I dati che Vi riguardano saranno archiviati e trattati nel
                rispetto di quanto stabilito in merito al trattamento dei dati
                degli utenti ai fini dell’art. 13 del D.lgs. n. 196/2003, Codice
                in materia di protezione dei dati personali, e ai fini dell’art.
                13 del Regolamento UE n. 2016/679, ed usati esclusivamente per
                la compilazione della risposta.
              </b>
            </Form.Label>
            <Form.Check
              required
              type="checkbox"
              name="Privacy Accettata"
              label="Accetto"
            />
          </Form.Group>
          <div className="text-center">
            <Button className="btnVerde" type="submit">
              Invia la tua richiesta
            </Button>
          </div>
        </Form>
      </CardPagina>
    </Container>
  </Layout>
)

export default RichiestaInformazioni
